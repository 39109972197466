<template>
  <div>
    <Header :customer="data.data.customer" :color="data.data.color" />
    <div :style="{ height: '150px' }"></div>
    <Hero :info="data.data.info" :color="data.data.color" />
    <Specialist :specialist="data.data.specialist" :color="data.data.color" />
    <Presentation
      :presentation="data.data.presentation"
      :color="data.data.color"
    />
    <Services :services="data.data.services" />
    <Achievements :achievements="data.data.achievements" />

    <Intervention
      :intervention="data.data.intervention"
      :color="data.data.color"
    />
    <Prices :prices="data.data.prices" :color="data.data.color" />
    <Partenairs :partenairs="data.data.partenairs" :color="data.data.color" />

    <Testimonials
      :testimonials="data.data.testimonials"
      :color="data.data.color"
    />
    <Footer :footer="data.data.footer" :color="data.data.color" />
  </div>
</template>
<script>
import Achievements from "./components/Achievements.vue";
import Header from "./components/Header.vue";
import Hero from "./components/Hero.vue";
import Intervention from "./components/Intervention.vue";
import Partenairs from "./components/Partenairs.vue";
import Presentation from "./components/Presentation.vue";
import Prices from "./components/Prices.vue";
import Services from "./components/Services.vue";
import Specialist from "./components/Specialist.vue";
import Testimonials from "./components/Testimonials.vue";
import Footer from "./components/Footer.vue";

import Data from "./assets/data.json";

export default {
  components: {
    Achievements,
    Header,
    Hero,
    Specialist,
    Services,
    Presentation,
    Intervention,
    Prices,
    Partenairs,
    Testimonials,
    Footer,
  },
  setup() {
    const data = Data;

    return {
      data,
    };
  },
};
</script>
