<template>
  <div class="component">
    <div class="container">
      <div class="row title">
        <h2>{{ achievements.title }}</h2>
      </div>
      <div class="row">
        <div class="masonry-container">
          <img
            v-for="(image, i) in achievements.images"
            :key="'image-' + i"
            :src="image"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    achievements: {
      type: Object,
      default: () => {
        return {
          title: "",
          images: [],
        };
      },
    },
  },
};
</script>
<style scoped>
.component {
  padding: 175px 0;
  border-radius: 0 0 56px 56px;
  position: relative;
  z-index: 3;
  background-color: white;
}
.title {
  width: 50%;
}

.title h2 {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 70px;
}

.masonry-container {
  column-count: 3;
  column-gap: 30px;
}

.masonry-container img {
  margin-bottom: 30px;
  border-radius: 16px;
}

img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .title {
    width: 90%;
  }

  .title h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .masonry-container {
    column-count: 2;
    column-gap: 20px;
  }

  .masonry-container img {
    margin-bottom: 20px;
    border-radius: 12px;
  }
}

@media screen and (max-width: 640px) {
  .masonry-container {
    column-count: 1;
  }

  .masonry-container img {
    margin-bottom: 20px;
  }
}
</style>
