<template>
  <section class="component">
    <div
      class="container"
      :style="{
        backgroundImage:
          'linear-gradient(90deg,' +
          color +
          ' 40.21%, rgba(11, 142, 54, 0) 100%),url(' +
          info.background +
          ')',
      }"
    >
      <div class="wrapper">
        <p class="description">{{ info.description }}</p>
        <h2 class="subtitle">{{ info.subtitle }}</h2>
        <div class="btn-wrapper">
          <a href="#footer"><div class="btn-contact">Nous contacter</div></a>
          <h2 class="title">{{ info.title }} à {{ info.city }}</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          subtitle: "",
          title: "",
          city: "",
          description: "",
          phone: "",
          background: "",
        };
      },
    },

    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup(props) {
    const contrast = ref(0);
    const colorContrasted = ref(false);

    onMounted(() => {
      checkColors("ffffff", props.color); // eslint-disable-line
      contrast.value = parseInt(colorData.contrast); // eslint-disable-line
      colorContrasted.value = contrast.value < 3 ? "#000" : "#fff";
    });

    return {
      colorContrasted,
    };
  },
};
</script>

<style scoped>
.component {
  padding-bottom: 100px;
  border-radius: 0 0 56px 56px;
  position: relative;
  z-index: 2;
  background-color: white;
}
.container {
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
}

.wrapper {
  padding: 56px;
}

.column-left {
  background-color: rgba(255, 255, 255, 0.7);
  width: 50%;
}

.subtitle {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 17px;
  margin-bottom: 15px;
  color: v-bind(colorContrasted);
  margin-top: 24px;
}

.description {
  font-size: 48px;
  font-weight: 700;
  color: v-bind(colorContrasted);
  width: 70%;
}

.btn-wrapper {
  width: fit-content;
}

.btn-contact {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: v-bind(color);
  background-color: white;
  text-align: center;
  border-radius: 3px;
  padding: 16px 0;
  margin-top: 140px;
  max-width: 330px;
}
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: v-bind(colorContrasted);
  margin-top: 16px;
}

@media screen and (max-width: 1024px) {
  .description {
    width: 90%;
  }
}

@media screen and (max-width: 640px) {
  .description {
    font-size: 30px;
  }

  .wrapper {
    padding: 40px 20px 20px 20px;
  }

  .btn-contact {
    margin-top: 80px;
  }
}
</style>
