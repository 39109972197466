<template>
  <div class="component">
    <div class="container">
      <div class="row description">
        <h2>{{ services.title }}</h2>
        <p>
          {{ services.description }}
        </p>
      </div>
      <div class="row service-wrapper">
        <div
          v-for="(service, i) in services.service"
          :key="'service-' + i"
          class="service"
        >
          <div
            class="image-wrapper"
            :style="{
              backgroundImage:
                ' linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), url(' +
                service.img +
                ')',
            }"
          ></div>
          <div class="text-wrapper">
            <h3>{{ "0" + (i + 1) + " — " + service.name }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    services: {
      type: Object,
      default: () => {
        return {
          title: "",
          description: "",
          service: [
            {
              name: "",
              description: "",
              img: "",
            },
          ],
        };
      },
    },
  },
};
</script>
<style scoped>
.description h2 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 400;
  margin-bottom: 24px;
  width: 70%;
}

.description p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  width: 70%;
}

.service {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.service-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 33px;
  margin-top: 60px;
}

.image-wrapper {
  height: 330px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

h3 {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: #0a0a0a;
}

.service p {
  font-size: 18px;
  line-height: 32px;
}

.text-wrapper {
  margin: 24px 24px 32px 24px;
}

@media screen and (max-width: 768px) {
  .description p {
    width: 100%;
  }

  .description h2 {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  }

  .service-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .image-wrapper {
    height: 250px;
  }
}

@media screen and (max-width: 640px) {
  .service-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
