<template>
  <div id="footer" class="component">
    <div class="container">
      <div class="row title">
        <div class="column-left">
          <p>{{ footer.text }}</p>
          <h2>{{ footer.city }}</h2>
        </div>
        <div class="column-right">
          <button class="typeform">Nous écrire</button>
          <button class="phone">
            <font-awesome-icon class="icon" icon="phone" /> {{ footer.number }}
          </button>
        </div>
      </div>

      <div class="row">
        <ul class="list-info">
          <li class="item-container">
            <div class="item-wrapper">
              <div class="title">
                <div class="icon-wrapper">
                  <font-awesome-icon class="icon" icon="map-location-dot" />
                </div>
                <p>Adresse</p>
              </div>
              <div class="content">
                <span class="address"> {{ footer.address }} </span>
                <span class="space">-</span>
                <span class="city"> {{ footer.code }} {{ footer.city }} </span>
              </div>
            </div>
          </li>
          <li class="item-container">
            <div class="item-wrapper item-hourly">
              <div class="title">
                <div class="icon-wrapper">
                  <font-awesome-icon class="icon" icon="business-time" />
                </div>
                <p>Horaires</p>
              </div>
              <div class="content hourly">
                <ul v-if="allHourly">
                  <li
                    v-for="(hour, value, i) in footer.hourly"
                    :key="'hour-' + i"
                  >
                    {{ value }} : {{ hour }}
                  </li>
                </ul>
                <p v-else>Aujourd'hui : {{ footer.hourly[today] }}</p>
                <div class="hourly-icon" @click="allHourly = !allHourly">
                  <font-awesome-icon class="icon" icon="caret-down" />
                </div>
              </div>
            </div>
          </li>
          <li class="item-container">
            <div class="item-wrapper">
              <div class="title">
                <div class="icon-wrapper">
                  <font-awesome-icon class="icon" icon="compass" />
                </div>
                <p>Zone d'intervention</p>
              </div>
              <div class="content">
                {{ footer.zone }}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <iframe
          :src="footer.map"
          width="100%"
          height="350"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <footer>
      <div class="container">
        <p>
          Réalisation
          <a
            target="_blank"
            rel="nofollow noopener"
            href="https://www.aleo.agency/"
            ><img
              height="14"
              src="https://static.nancomcy.fr/static/logos/logos-footers/aleo/logo-aleo.svg"
              alt=""
          /></a>

          | © <span> {{ footer.customer }} </span> |
          <a href="/mentions-legales/">Mentions légales</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  props: {
    footer: {
      type: Object,
      default: () => {
        return {
          text: "",
          city: "",
          address: "",
          number: "",
          code: "",
          hourly: "",
          zone: "",
          map: "",
          customer: "",
        };
      },
    },

    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup() {
    const today = computed(() => {
      let date = new Date();
      let days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      return days[date.getDay()];
    });

    const allHourly = ref(false);

    return {
      today,
      allHourly,
    };
  },
};
</script>
<style scoped>
.component {
  background-color: v-bind(color);
  padding: 193px 0 0 0;
  margin-top: -100px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title .column-left p {
  color: white;
  line-height: 29px;
  font-size: 16px;
  font-weight: 500;
}

.title .column-left h2 {
  color: white;
  line-height: 63px;
  font-size: 58px;
  font-weight: 300;
  font-style: italic;
}

.column-right {
  display: flex;
  gap: 24px;
}

button {
  font-size: 18px;
  line-height: 31px;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
}

.typeform {
  color: v-bind(color);
  background-color: white;
  font-weight: 700;
}

.phone {
  background-color: v-bind(color);
  color: white;
  font-weight: 600;
}

ul.list-info {
  padding: 0;
  margin: 0;
  margin-top: 70px;
}

ul {
  list-style-type: none;
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.item-hourly {
  align-items: flex-start;
}

.title {
  font-size: 30px;
  color: white;
  font-style: italic;
}
.icon-wrapper {
  width: 70px;
}

.content {
  color: white;
  font-size: 18px;
  line-height: 33px;
  font-weight: 500;
  font-style: italic;
}

.content .space {
  margin: 0 5px;
}

.hourly {
  display: flex;
  gap: 20px;
}

.hourly-icon {
  border: 1px solid;
  height: 30px;
  width: 30px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

iframe {
  margin-top: 24px;
  border-radius: 5px;
}

footer {
  margin-top: 90px;
  text-align: center;
  padding: 20px 0;
  background-color: #181818;
  color: #777;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

footer img {
  margin: 0 5px;
}

footer span {
  text-transform: uppercase;
}

footer a {
  text-decoration: none;
  color: #777;
}

@media screen and (max-width: 768px) {
  .title {
    flex-direction: column;
    gap: 20px;
  }

  .title .column-left {
    align-self: flex-start;
  }

  .title .column-left h2 {
    font-size: 40px;
  }

  .item-wrapper {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .item-wrapper .title {
    align-self: flex-start;
    flex-direction: row;
  }

  .item-wrapper .content {
    align-self: flex-end;
  }

  .icon-wrapper {
    width: unset;
  }

  .content .space {
    display: none;
  }

  li:first-child .content {
    display: flex;
    flex-direction: column;
  }

  li:first-child .content span {
    align-self: flex-end;
  }
}

@media screen and (max-width: 640px) {
  .column-right button {
    font-size: 14px;
  }

  .title {
    font-size: 20px;
  }

  .content {
    font-size: 16px;
  }
}
</style>
