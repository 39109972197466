<template>
  <div class="component">
    <div class="container">
      <div class="row">
        <div class="text">
          <h2>{{ partenairs.title }}</h2>
          <p>{{ partenairs.description }}</p>
        </div>
        <div class="swiper-partenairs swiper">
          <div class="swiper-wrapper">
            <div
              v-for="(partenair, i) in partenairs.images"
              :key="'slide-' + i"
              class="swiper-slide"
            >
              <img :src="partenair" alt="" />
            </div>
          </div>
          <div class="next-btn navigation-btn">
            <font-awesome-icon class="icon" icon="angle-right" />
          </div>
          <div class="prev-btn navigation-btn">
            <font-awesome-icon class="icon" icon="angle-left" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";
import Swiper, { Navigation, Autoplay } from "swiper";
export default {
  props: {
    partenairs: {
      type: Object,
      default: () => {
        return {
          title: "",
          description: "",
          images: [],
        };
      },
    },

    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup() {
    onMounted(() => {
      new Swiper(".swiper-partenairs", {
        modules: [Navigation, Autoplay],
        slidesPerView: 2,
        loop: true,
        autoplay: {
          delay: 2500,
        },
        navigation: {
          nextEl: ".next-btn",
          prevEl: ".prev-btn",
        },
      });
    });
  },
};
</script>
<style scoped>
.component {
  padding-top: 200px;
  padding-bottom: 175px;
  background-color: #f7f7f7;
}
.row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px;
}

.text h2 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 400;
  margin-bottom: 40px;
}

.text p {
  font-size: 18px;
  line-height: 32px;
}

.swiper {
  max-width: 100%;
  display: flex;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 70%;
}

.navigation-btn {
  color: v-bind(color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  font-size: 25px;
  cursor: pointer;
}

.next-btn {
  right: 0;
}
.prev-btn {
  left: 0;
}

@media screen and (max-width: 768px) {
  .row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .text h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
</style>
