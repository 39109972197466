<template>
  <div class="component">
    <div class="container">
      <div class="wrapper">
        <div class="row">
          <p class="title">Témoignages</p>
        </div>
        <div class="row">
          <div class="testimonial-container swiper">
            <div class="swiper-wrapper">
              <div
                v-for="(opinion, i) in testimonials.opinions"
                :key="'opinion-' + i"
                class="swiper-slide"
              >
                <div class="text-container">
                  <p>{{ opinion.text }}</p>
                  <div class="stars">
                    <span v-for="j in opinion.stars" :key="'star-' + j">
                      ★
                    </span>
                  </div>
                </div>
                <div class="name-container">
                  <div class="first-letter">{{ opinion.name[0] }}</div>
                  <div class="name-wrapper">
                    <p class="name">{{ opinion.name }}</p>
                    <p class="origin">{{ opinion.origin }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="navigation">
              <div class="prev">
                <font-awesome-icon class="icon" icon="angle-left" />
              </div>
              <div class="next">
                <font-awesome-icon class="icon" icon="angle-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import Swiper, { Navigation, Autoplay } from "swiper";

export default {
  props: {
    testimonials: {
      type: Object,
      default: () => {
        return {
          title: "",
          opinions: [
            {
              name: "",
              text: "",
              origin: "",
              stars: 0,
            },
          ],
        };
      },
    },
    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup(props) {
    const bgColor = computed(() => {
      return props.color + "10";
    });

    onMounted(() => {
      new Swiper(".testimonial-container", {
        modules: [Navigation, Autoplay],
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2800,
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
        },
      });
    });

    return {
      bgColor,
    };
  },
};
</script>
<style scoped>
.component {
  padding: 90px 0;
  border-radius: 0 0 56px 56px;
  background-color: white;
  position: relative;
  z-index: 2;
}
.container {
  background-color: v-bind(bgColor);
  border-radius: 56px;
}

.wrapper {
  padding: 66px;
}

.title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 40px;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-container p,
.stars {
  font-size: 48px;
  line-height: 62px;
  color: v-bind(color);
  font-style: italic;
}

.name {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.origin {
  font-size: 20px;
  line-height: 26px;
}

.name-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
}

.first-letter {
  width: 64px;
  height: 64px;
  background-color: v-bind(color);
  border-radius: 99px;
  color: white;
  font-size: 36px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  color: v-bind(color);
  display: flex;
  gap: 20px;
  font-size: 25px;
  z-index: 3;
}

.navigation > div {
  height: 64px;
  width: 64px;
  background-color: white;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 46px;
  }

  .text-container p,
  .stars {
    font-size: 35px;
    line-height: 45px;
  }
}

@media screen and (max-width: 640px) {
  .container {
    border-radius: 32px;
  }
  .wrapper {
    padding: 46px 32px 32px 32px;
  }
  .text-container p,
  .stars {
    font-size: 20px;
    line-height: 25px;
  }

  .first-letter {
    display: none;
  }

  .navigation {
    font-size: 20px;
    gap: 12px;
  }

  .navigation > div {
    height: 40px;
    width: 40px;
  }

  .name {
    font-size: 15px;
  }

  .origin {
    font-size: 15px;
  }
}
</style>
