<template>
  <div class="component">
    <div class="container">
      <div class="row title">
        <h2><span>Les prix </span> de nos réalisations</h2>
      </div>
      <div class="row prices">
        <div class="head">
          <p>PRESTATION</p>
          <p>PRIX</p>
        </div>
        <div class="prices-list">
          <div
            v-for="(price, i) in prices"
            :key="'price-' + i"
            class="prices-row"
          >
            <div>
              <p class="name">{{ price.title }}</p>
              <p class="description">{{ price.description }}</p>
            </div>
            <div class="price">{{ price.price }} €</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  props: {
    prices: {
      type: Object,
      default: () => {
        return {
          title: "",
          description: "",
          price: "",
        };
      },
    },
    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup(props) {
    const bgColor = computed(() => {
      return props.color + "10";
    });

    return {
      bgColor,
    };
  },
};
</script>
<style scoped>
.component {
  padding-top: 100px;
  padding-bottom: 88px;
  border-radius: 0 0 56px 56px;
}

.title {
  margin-top: 80px;
  margin-bottom: 48px;
}

.title h2 {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  margin-top: 0;
}

.title span {
  font-style: italic;
  color: v-bind(color);
}

.head {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.prices-row {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.price {
  font-size: 18px;
  line-height: 46px;
}

.name {
  font-size: 26px;
  line-height: 46px;
}

.description {
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .title h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .name {
    font-size: 20px;
  }
}
</style>
