<template>
  <div class="component">
    <div class="container">
      <div class="row title">
        <h2>
          <span> {{ intervention.steps.length }} étapes </span> d'intervention
        </h2>
      </div>
      <div class="row steps-wrapper">
        <div
          v-for="(step, i) in intervention.steps"
          :key="'step-' + i"
          class="step"
        >
          <div>
            <h6>{{ i + 1 }}</h6>
          </div>

          <h5>{{ step.title }}</h5>
          <p>{{ step.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
export default {
  props: {
    intervention: {
      type: Object,
      default: () => {
        return {
          title: "",
          steps: [
            {
              title: "",
              description: "",
            },
          ],
        };
      },
    },

    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup(props) {
    const bgColor = computed(() => {
      return props.color + "10";
    });

    return {
      bgColor,
    };
  },
};
</script>
<style scoped>
.component {
  margin-top: -80px;
  padding-top: 180px;
  padding-bottom: 120px;
  background-color: v-bind(bgColor);
  border-radius: 0 0 56px 56px;
  position: relative;
  z-index: 2;
}

.title {
  margin-bottom: 80px;
}

.title h2 {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  margin: 0;
}

.title span {
  color: v-bind(color);
  font-style: italic;
}

.steps-wrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 32px;
}

.step > div {
  font-size: 24px;
  line-height: 46px;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: fit-content;
  padding: 4px;
  border-radius: 999px;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step h6 {
  margin: 0;
}

.step h5 {
  font-size: 32px;
  line-height: 46px;
  font-weight: 400;
  margin-top: 28px;
  margin-bottom: 8px;
}

.step p {
  font-size: 18px;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .steps-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .title h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .step h5 {
    font-size: 26px;
  }
}

@media screen and (max-width: 640px) {
  .steps-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
