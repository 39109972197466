<template>
  <section class="component">
    <div class="container-fluid">
      <div class="row">
        <div class="column-left">
          <img :src="presentation.img" alt="" />
        </div>
        <div class="column-right">
          <h3>Présentation de l’entreprise</h3>
          <p>{{ presentation.description }}</p>
          <button>Obtenir nos tarifs</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    presentation: {
      type: Object,
      default: () => {
        return {
          description: "description",
          img: "",
        };
      },
    },
    color: {
      type: String,
      default: "#2C99EE",
    },
  },
};
</script>
<style scoped>
.component {
  background-color: white;
  padding: 147px 0;
}

.row {
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 130px;
}

.column-right {
  margin-right: 10%;
}

p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}

h3 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 400;
  margin-bottom: 24px;
}

img {
  width: 100%;
}

button {
  background-color: v-bind(color);
  color: white;
  padding: 20px 28px;
  border-radius: 3px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 24px;
}

@media screen and (max-width: 768px) {
  .container-fluid {
    width: 80%;
  }
  .component {
    padding: 97px 0;
  }
  .row {
    gap: 80px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  h3 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 640px) {
  .container-fluid {
    width: 88%;
  }
}
</style>
