<template>
  <div ref="header" class="component" :class="[isSticky ? 'box-shadowed' : '']">
    <nav class="navbar-container">
      <div class="navbar-wrapper container">
        <div class="navbar-left">
          <div class="logo">
            <img
              id="logo-image"
              :src="customer.logo"
              alt="d"
              class="logo-image"
            />
          </div>
          <div class="name">
            <h1>
              {{ customer.name }} <br />
              à
              <a :href="customer.map" target="_blank"> {{ customer.city }} </a>
            </h1>
          </div>
        </div>
        <div class="navbar-right">
          <img width="180" src="../assets/img/google-rating.svg" alt="" />
          <div class="btn-phone">
            <button>
              <font-awesome-icon class="icon" icon="phone" />
              {{ customer.phone }}
            </button>
          </div>
          <div v-if="isSticky" class="btn-contact">
            <button>Contactez-nous</button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";

export default {
  props: {
    customer: {
      type: Object,
      default: () => {
        return {
          logo: "",
          name: "",
          city: "",
          map: "",
          phone: "",
        };
      },
    },

    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup() {
    const isSticky = ref(false);
    const header = ref(null);

    onMounted(() => {
      window.addEventListener("scroll", onScroll);
    });

    const onScroll = function () {
      let sticky = header.value.offsetTop;
      if (window.pageYOffset > sticky) {
        isSticky.value = true;
      } else {
        isSticky.value = false;
      }
    };

    return {
      onScroll,
      isSticky,
      header,
    };
  },
};
</script>
<style scoped>
.component {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 10;
}
.box-shadowed {
  box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.2);
}
.navbar-container {
  padding-top: 22px;
  padding-bottom: 22px;
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
}

.navbar-left {
  display: flex;
  gap: 15px;
}
.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 80px;
}

.name {
  display: flex;
  align-items: center;
}

.name h1 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.name a {
  color: v-bind(color);
}

.navbar-right {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.navbar-right > div {
  text-align: center;
}

.navbar-right button {
  font-size: 16px;
  padding: 16px 24px;
  font-weight: 700;
  margin-bottom: 5px;
  border-radius: 3px;
  transition: 0.3s;
}

.btn-contact > button {
  color: white;
  border: 2px solid v-bind(color);
  background-color: v-bind(color);
}

/* .btn-contact > button:hover {
  color: white;
  background: var(--primary-color);
} */

.btn-phone > button {
  color: v-bind(color);
  border: 2px solid v-bind(color);
  line-height: 19px;
  width: max-content;
}

/* .btn-phone > button:hover {
  background: #1bd1a3;
  border-color: #1bd1a3;
} */

.icon {
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .navbar-right img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .btn-contact {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .navbar-right {
    display: none;
  }

  .name h1 {
    font-size: 18px;
  }
}
</style>
