<template>
  <section class="component">
    <div class="container">
      <div class="row">
        <div class="title">
          {{ specialist.title }} à <span>{{ specialist.city }}</span>
        </div>
      </div>
      <div class="row">
        <div class="advantages-container">
          <div
            v-for="(advantage, i) in specialist.advantages"
            :key="'advantage-' + i"
            class="advantage"
          >
            <div class="icon-container">
              <font-awesome-icon class="icon" :icon="advantage.icon" />
            </div>

            <h4 class="advantage-title">{{ advantage.name }}</h4>
            <p class="advantage-description">
              {{ advantage.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { computed } from "vue";

export default {
  props: {
    specialist: {
      type: Object,
      default: () => {
        return {
          title: "",
          city: "",
          advantages: [
            {
              icon: "",
              name: "",
              description: "",
            },
          ],
        };
      },
    },
    color: {
      type: String,
      default: "#2C99EE",
    },
  },

  setup(props) {
    const bgColor = computed(() => {
      return props.color + "10";
    });

    return {
      bgColor,
    };
  },
};
</script>
<style scoped>
.component {
  margin-top: -80px;
  padding-top: 80px;
  background-color: v-bind(bgColor);
  position: relative;
  z-index: 1;
  border-radius: 0 0 56px 56px;
}
.title {
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  width: 70%;
}

.title span {
  color: v-bind(color);
  font-style: italic;
}

.container {
  padding: 137px 0;
}

.row:first-child {
  margin-bottom: 120px;
}

.advantages-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 32px;
}

.icon-container {
  width: 105px;
  height: 105px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 999px;
}

.icon {
  font-size: 42px;
  color: v-bind(color);
}
.advantage {
  text-align: center;
}

.advantage-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 24px;
  margin-bottom: 18px;
}

.advantage-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .title {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .advantages-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 640px) {
  .title {
    font-size: 40px;
    line-height: 45px;
  }

  .advantages-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
